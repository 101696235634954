@use '../../assets/vendor/utils.scss' as *;

.gender-selector {
  width: 100%;
  display: flex;
  gap: 4px;

  @media screen and (max-width: 766px) {
    justify-content: space-evenly;
  }

  .button-with-icon {
    padding: 12px 16px;
    max-width: 136px;
    width: 100%;
    height: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    line-height: 120%;
    background: $gray;
    border: none;
    border-radius: 12px;

    @media screen and (max-width: 766px) {
      height: 122px;
      font-weight: 400;
    }

    &__icon {
      width: 26px;
      height: 26px;

      @media screen and (max-width: 766px) {
        width: 40px;
        height: 40px;
        background-size: cover !important;
      }
    }

    &.active {
      background: #fff8da;
      border: 1px solid #ffe14b;

      @media screen and (max-width: 766px) {
        font-weight: 500;
        background: #ffe97c;
        box-shadow: 0px 4px 6.1px 0px rgba(255, 233, 124, 0.3);
      }
    }
  }
}
