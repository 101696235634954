@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.skeleton-lines {
  position: absolute;
  top: -30px;
  left: -10px;
  z-index: -1;
  width: 105%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 8px;
  
  @media screen and (max-width: 766px) {
    left: 0;
    width: 100%;
    justify-content: center;
    row-gap: 6px;
    column-gap: 8px;
    overflow: hidden;
  }
}

.skeleton-line {
  height: 10px;
  background-color: #f6f6f6;
  border-radius: 8px;
  animation: pulse 1.5s infinite;
  
  @media screen and (max-width: 766px) {
    height: 8px;
  }
}

.skeleton-line:nth-child(1) { width: 15%; }
.skeleton-line:nth-child(2) { width: 75%; }
.skeleton-line:nth-child(3) { width: 70%; }
.skeleton-line:nth-child(4) { width: 15%; }
.skeleton-line:nth-child(5) { width: 95%; }
.skeleton-line:nth-child(6) { width: 85%; }
.skeleton-line:nth-child(7) { width: 55%; }
.skeleton-line:nth-child(8) { width: 45%; }
.skeleton-line:nth-child(9) { width: 45%; }
.skeleton-line:nth-child(10) { width: 15%; }
.skeleton-line:nth-child(11) { width: 70%; }
.skeleton-line:nth-child(12) { width: 70%; }
.skeleton-line:nth-child(13) { width: 15%; }
.skeleton-line:nth-child(14) { width: 95%; }
.skeleton-line:nth-child(15) { width: 85%; }
.skeleton-line:nth-child(16) { width: 95%; }
.skeleton-line:nth-child(17) { width: 55%; }
.skeleton-line:nth-child(18) { width: 45%; }
.skeleton-line:nth-child(19) { width: 85%; }
.skeleton-line:nth-child(20) { width: 45%; }
.skeleton-line:nth-child(21) { width: 95%; }
.skeleton-line:nth-child(22) { width: 85%; }
.skeleton-line:nth-child(23) { width: 95%; }
.skeleton-line:nth-child(24) { width: 75%; }
.skeleton-line:nth-child(25) { width: 15%; }
.skeleton-line:nth-child(26) { width: 85%; }
.skeleton-line:nth-child(27) { width: 45%; }
.skeleton-line:nth-child(28) { width: 95%; }
.skeleton-line:nth-child(29) { width: 65%; }
.skeleton-line:nth-child(30) { width: 25%; }
