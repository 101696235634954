@use '../../../assets/vendor/utils.scss' as *;

.services-content {
  padding: 32px 16px;
  display: flex;
  justify-content: center;
  background: $gray;
  border-radius: 32px;

  @media screen and (max-width: 766px) {
    padding: 24px 8px 56px;
    border-radius: 24px;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(4, minmax(auto, 272px));
    grid-template-rows: minmax(314px, auto);
    gap: 20px 16px;

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      grid-template-columns: repeat(3, minmax(auto, 322px));
      gap: 32px 16px;
    }
    
    @media screen and (min-width: 541px) and (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(auto, 322px));
      max-width: 579px;
      gap: 32px 16px;
    }

    @media screen and (max-width: 540px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 32px 16px;
    }
  }

  &__special-card {
    grid-column: span 2;
    min-width: 272px;
    padding: 0px 16px 12px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 24px;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.16);

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      grid-column: span 3;
    }

    @media screen and (max-width: 766px) {
      padding: 0 8px 12px 8px;
    }

    &-text {
      max-width: 316px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      flex: 1 auto;

      @media screen and (min-width: 769px) and (max-width: 1024px) {
        padding: 36px 0;
      }

      @media screen and (max-width: 766px) {
        padding: 36px 8px;
        max-width: none;
      }
    }

    &-title {
      font-size: 32px;
      font-weight: 700;
    }

    &-description {
      font-size: 14px;
      line-height: 140%;
    }
  }

  &__buttons {
    width: 100%;
    border-top: 1px solid #D7E2E4;
  }

  &__button-setting {
    margin-top: 12px;
    height: 32px;
    font-size: 12px;
    font-weight: 700;
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    border-radius: 12px;

    @media screen and (max-width: 766px) {
      height: 44px;
      background: #FFE14B;
      border: 1px solid #FFE14B;
    }

    .button-with-icon {
      &__icon {
        width: 22px;
        height: 22px;

        @media screen and (max-width: 766px) {
          display: none;
        }
      }
    }
  }
}
