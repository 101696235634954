@use '../../../assets/vendor/utils.scss' as *;

.info-content {
  margin-top: 58px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    display: flex;
    align-items: center;

    & p {
      position: absolute;
      top: -15px;
      right: -35px;
      max-width: 190px;
      width: 100%;
      font-size: 18px;
      font-weight: 700;

      @media screen and (max-width: 766px) {
        right: -10px;
        font-size: 16px;
        line-height: 140%;
      }
    }

    .lottie-animation {
      position: absolute;
      top: -220px;
      left: -200px;

      @media screen and (max-width: 766px) {
        top: -100px;
        left: 20%;
        transform: translateX(-45%);
      }
    }
  }

  &__list {
    padding: 24px 16px 12px;
    font-size: 14px;
    line-height: 120%;
    color: #4f4f44;

    @media screen and (max-width: 766px) {
      padding: 16px 8px 0;
    }

    & li {
      margin-top: 8px;
      margin-left: 8px;
      font-size: 12px;
      line-height: 140%;
    }
  }

  &__buttons {
    & p {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.12px;
      color: $gray-deep-dark;
      text-align: center;

      @media screen and (max-width: 766px) {
        font-size: 10px;
        letter-spacing: 0.1px;
      }
    }
  }

  &__random-button {
    padding: 8px 0;
    max-width: 360px;
    min-width: 360px;
    width: 100%;
    height: 38px;
    gap: 4px;
    font-size: 14px;
    line-height: 120%;
    color: #4f4f4f;
    background: #f2f1ed;
    border: 2px solid #f2f1ed;
    border-radius: 12px;

    @media screen and (max-width: 766px) {
      max-width: none;
      min-width: 0;
    }

    .button-with-icon__icon {
      width: 22px;
      height: 22px;
    }
  }
}
