@use '../../../assets/vendor/utils.scss' as *;

.toggler {
  padding: 0 3px;
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  background-color: $gray-toggler;
  border-radius: 30px;
  transition: all 0.3s;

  @media screen and (max-width: 766px) {
    width: 50px;
    height: 30px;
  }

  &__thumb {
    width: 20px;
    height: 20px;
    background-color: $white;
    border-radius: 50%;
    transition: all 0.3s;

    @media screen and (max-width: 766px) {
      width: 25px;
      height: 25px;
    }
  }

  &.active {
    background-color: $yellow-main;

    .toggler__thumb {
      transform: translateX(14px);

      @media screen and (max-width: 766px) {
        transform: translateX(19px);
      }
    }
  }
}
