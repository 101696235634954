$white: #ffffff;
$black: #1e1e1e;

$gray: #f9f9fb;
$gray-deep-dark: #939393;

$blue-dirty: #d7e2e4;

$red-failed: #EA4335;

$black-dark: #303030;
$light-black: #4F4F4F;
$black-disabled: #707070;

$orange: #F3901C;
$orange-light: #FEB700;

$yellow-dark: #EEB010;
$yellow-main: #FFE14B;
$yellow-sub: #F2F1ED;
$yellow-light: #fff5cb;
$yellow-blond: #FFFBE6;

$green: #37ac38;
$green-success: #01BE02;
$lightgreen: #57C058;

$blue-light: #f1f9fd;

$gray-light: #FDFDFD;
$gray-toggler: #cecece;
$gray-dark: #ABABAB;
$gray-disabled: #F0F0F0;
