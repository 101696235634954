@use '../../../assets/vendor/utils.scss' as *;

.seo-content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: #f9f9fb;
  border-radius: 24px;

  @media screen and (max-width: 766px) {
    margin-top: 32px;
    padding: 32px 8px;
  }

  &__grid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      gap: 8px;
    }

    &-large {
      padding: 42px 0;
      max-width: 360px;
      width: 100%;
      max-height: 422px;
      height: 100%;
      display: flex;
      justify-content: center;
      border-radius: 16px;
      background: linear-gradient(
          342deg,
          rgba(167, 217, 199, 0.6) 16%,
          rgba(252, 246, 152, 0.6) 46%,
          rgba(248, 199, 156, 0.6) 96%
        ),
        #f0f0f0;
      box-shadow: 0px 2px 18.1px -17px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 766px) {
        padding: 16px 0;
      }

      &__container {
        padding: 8px;
        max-width: 180px;
        width: 100%;
        background: #fff;
        border-radius: 54px;
      }

      &__content {
        background: linear-gradient(
          156deg,
          #a7d9c7 4%,
          #fcf698 28%,
          #f8c79c 68%
        );
        border-radius: 48px;

        &-wrapper {
          padding: 24px 8px 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
        }

        &-icons {
          display: flex;
          align-items: center;
          gap: 8px;

          &-yandex {
            width: 32px;
            height: 32px;
            background: url(~assets/images/yandexmapWithBg.svg) center/contain
              no-repeat;
            flex-shrink: 0;
          }

          &-gis {
            width: 32px;
            height: 32px;
            background: url(~assets/images/2gis.svg) center/contain no-repeat;
            flex-shrink: 0;
          }
        }

        &-description {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          &-title {
            font-size: 19px;
            font-weight: 600;
            line-height: 110%;
            text-align: center;
          }

          &-text {
            font-size: 10px;
            line-height: 120%;
          }
        }

        &-qr {
          padding: 8px 12px 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          background: #fff;
          border-radius: 32px;

          &-text {
            padding: 8px;
            font-size: 8px;
            font-weight: 500;
            line-height: 120%;
            background: #f3f3f3;
            border-radius: 24px;
            text-align: center;

            & span {
              font-weight: 700;
            }
          }

          &-icon {
            width: 96px;
            height: 96px;
            background: url(~assets/images/qr.svg) center/contain no-repeat;
          }
        }
      }
    }

    &-container {
      padding: 16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 191px);
      gap: 8px;
      background: #fff;
      border-radius: 16px;

      @media screen and (max-width: 766px) {
        padding: 8px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 118px);
        gap: 4px;
      }

      .grid-item {
        max-width: 256px;
        width: 100%;
        max-height: 191px;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;

        @media screen and (max-width: 766px) {
          max-width: 182px;
          max-height: 118px;
          border-radius: 12px;
        }
      }

      @media screen and (max-width: 766px) {
        .grid-item:nth-child(5) {
          display: none;
        }
        .grid-item:nth-child(6) {
          display: none;
        }
      }
    }
  }

  &__info {
    padding: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    background: #fff;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      padding: 16px 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (max-width: 766px) {
        gap: 16px;
      }

      &-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 100%;
      }

      &-text {
        line-height: 140%;
      }
    }

    &-link {
      max-width: 160px;
      width: 100%;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 120%;
      background-color: #ffd500;
      border-radius: 16px;
      transition: background-color 0.5s;

      &:hover {
        background-color: #fff;
        border: 2px solid #ffd500;
      }

      @media screen and (max-width: 766px) {
        max-width: none;
        height: 48px;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}

.seo-description {
  margin-top: 32px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  @media screen and (max-width: 766px) {
    margin-top: 0;
    padding: 56px 16px;
    flex-direction: column;
  }

  &__title {
    max-width: 440px;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
  }

  &__info {
    max-width: 640px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 766px) {
      &-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 120%;
      }
    }

    &-button-more {
      align-self: flex-end;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.12px;
      color: #939393;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
