@use '../../assets/vendor/utils.scss' as *;

.main {
  flex: 1 0 auto;

  &__inner {
    margin-top: 22px !important;
    display: flex;
    flex-direction: column;
    gap: 22px;

    @media screen and (max-width: 766px) {
      margin-top: 32px !important;
      gap: 32px;
    }
  }

  &__container {
    margin-top: 8px;
    margin-bottom: 32px;
    padding: 32px;
    display: flex;
    gap: 32px;
    background-color: $gray;
    border-radius: 24px;

    @media screen and (max-width: 766px) {
      order: 3;
      margin: 0;
      padding: 0;
      flex-direction: column;
      background-color: $white;
    }
  }
  &__settings {    
    @media screen and (max-width: 766px) {
      &-star {
        order: 1;
        padding: 20px 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-radius: 24px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

        &-title {
          padding-left: 8px;
          font-weight: 600;
          line-height: 120%;
        }
      }
    }

    &-label {
      padding-left: 8px;
      padding-bottom: 4px;
      font-size: 14px;
      font-weight: 700;
      line-height: 120%;

      @media screen and (max-width: 766px) {
        font-size: 16px;
      }
    }

    &-rating {
      &-container {
        margin-top: 8px;
        padding: 8px 8px 16px;

        display: flex;
        flex-direction: column;
        gap: 20px;
        background: $white;
        border-radius: 16px;
        box-shadow: 0 2px 18px -17px rgba(0, 0, 0, 0.1);

        @media screen and (max-width: 766px) {
          padding: 16px 8px 32px;
          font-size: 16px;
          border-radius: 0 0 24px 24px;
          box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.08);
        }

        &-others {
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media screen and (max-width: 766px) {
            gap: 12px;

            &-title {
              padding-left: 8px;
              font-size: 14px;
              color: #9f9f9f;
            }
          }
        }
      }
    }

    &-product {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: 766px) {
        padding: 32px 8px;
        gap: 24px;
        background: $white;
        border-radius: 24px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
      }

      & input {
        padding-left: 16px;
        width: 100%;
        height: 44px;
        background-color: $white;
        border: 1px solid $blue-dirty;
        border-radius: 8px;

        @media screen and (max-width: 766px) {
          margin-top: 8px;
          padding-left: 8px;
          height: 40px;
        }

        &::placeholder {
          font-size: 14px;
          font-weight: 300;
          color: $gray-deep-dark;

          @media screen and (max-width: 766px) {
            font-size: 12px;
          }
        }
      }

      &-write {
        &-reviews {
          padding-left: 8px;
          padding-right: 8px;
          display: flex;
          align-items: center;
          gap: 8px;

          @media screen and (max-width: 766px) {
            padding: 0;
            flex-direction: column;
            gap: 12px;
          }

          & p {
            max-width: 72px;
            width: 100%;
            font-size: 14px;
            line-height: 120%;
            color: $gray-deep-dark;

            @media screen and (max-width: 766px) {
              padding-left: 8px;
              max-width: none;
              font-size: 16px;
              font-weight: 600;
              color: $black;
            }
          }
        }
      }

      &-error {
        padding-left: 8px;
        font-size: 14px;
        line-height: 120%;
        color: $red-failed;

        @media screen and (max-width: 766px) {
          margin-top: 12px;
          font-size: 12px;
        }
      }

      input.error {
        border: 1px solid $red-failed;
      }
    }

    &-create-button {
      margin-top: 24px;
      padding: 6px 12px;
      font-size: 20px;
      font-weight: 500;
      line-height: 120%;
      background: #ffd500;
      border: 2px solid #ffd500;
      border-radius: 12px;

      @media screen and (max-width: 766px) {
        height: 54px;
        border-radius: 20px;
      }

      .button-with-icon__icon {
        width: 32px !important;
        height: 32px !important;
        background-size: contain !important;
      }
    }
  }

  &_result {
    padding: 8px 12px;
    background: linear-gradient(82deg, #f5e4ff 12%, #faebe7 54%, #fff4cb 102%);
    border-radius: 24px;

    // запрет копирования текста
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (max-width: 766px) {
      margin-top: 32px;
      padding: 8px;
    }

    &__container {
      height: 100%;
      display: flex;
      flex-direction: column;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.3) 0%,
        #fff 14.89%
      );
      border-radius: 16px;
      box-shadow:
        0px 4px 4px 0px rgba(0, 0, 0, 0.05),
        0px 4px 41px 0px rgba(255, 255, 255, 0.24) inset;

      @media screen and (max-width: 766px) {
        background: none;
        box-shadow: none;
      }
    }

    &-generation {
      z-index: 1;
      padding: 24px 16px 16px;
      max-width: 540px;
      min-width: 530px;
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      background: $white;
      border-radius: 16px;
      overflow: hidden;

      @media screen and (max-width: 766px) {
        z-index: 11;
        padding: 8px 8px 16px;
        max-width: none;
        min-width: 0;
        width: auto;
      }

      &-content {
        position: relative;
        z-index: 1;
        margin: auto;
        max-width: 360px;
        width: 100%;

        &-review {
          margin: 0 auto;
          padding: 24px 16px;
          max-width: 360px;
          font-size: 14px;
          font-weight: 300;
          line-height: 140%;
        }
      }

      &-buttons {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid $blue-dirty;

        &-description {
          font-size: 14px;
          line-height: 120%;
          color: #939393;
        }

        &__container {
          margin-top: 12px;
          display: flex;
          column-gap: 12px;
          //justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 766px) {
            border-top: none;
          }
        }
      }

      &-function-buttons {
        display: flex;
        align-items: center;
        gap: 4px;

        @media screen and (max-width: 766px) {
          width: 100%;
          justify-content: center;
        }

        .button-with-icon__icon {
          width: 22px;
          height: 22px;
        }
      }

      &-repeat-button {
        height: 56px;
        padding: 0 12px;
        gap: 4px;
        font-size: 14px;
        line-height: 120%;
        background: $gray-disabled;
        border: 2px solid $gray-disabled;
        flex-shrink: 0;

        @media screen and (max-width: 766px) {
          max-width: 154px;
          height: 44px;
        }
      }

      &-copy-button {
        max-width: 108px;
        width: 100%;
        height: 56px;
        background: $gray-disabled;
        border: 2px solid $gray-disabled;
        border-radius: 12px;
        flex-shrink: 0;

        @media screen and (max-width: 766px) {
          max-width: 154px;
          height: 44px;
        }
      }

      &-post-button {
        //max-width: 224px;
        //width: 100%;
        height: 56px;
        font-size: 20px;
        border-radius: 12px;

        @media screen and (max-width: 766px) {
          max-width: none;
        }

        .button-with-icon__icon {
          order: 1;
          width: 36px;
          height: 36px;
          background-size: contain !important;
        }
      }
    }
  }
}
