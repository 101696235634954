@use '../../../assets/vendor/utils.scss' as *;

.result-header {
  position: relative;
  padding: 16px 96px 24px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: clip;

  @media screen and (max-width: 766px) {
    padding: 12px 4px 8px;
    gap: 24px;
  }

  &__container {
    &-write-human {
      position: relative;
      z-index: 1;
      padding: 20px 16px;
      max-width: 435px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      background: $white;
      border-radius: 16px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

      @media screen and (max-width: 766px) {
        padding: 12px;
        background: #FFF;
        border-radius: 24px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
      }

      &-title {
        @media screen and (max-width: 766px) {
          max-width: 200px;
          width: 100%;
        }

        & p {
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;

          @media screen and (max-width: 766px) {
            font-size: 16px;
            font-weight: 600;
          }
        }

        & span {
          font-size: 12px;
          font-weight: 400;
          line-height: 120%;
          color: #4f4f44;

          @media screen and (max-width: 766px) {
            font-weight: 300;
          }
        }
      }

      &-subtitle {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.12px;
        color: $white;
        background: #57c058;
        border-radius: 0px 16px 0px 8px;
      }
    }
  }

  &__robots {
    &-icon {
      position: absolute;
      top: -50px;
      right: -40px;
      z-index: 1;
      width: 174px;
      height: 250px;
      object-fit: cover;

      @media screen and (max-width: 766px) {
        z-index: 10;
      }
    }
  }
}
