.loading-content {
  .lottie-animation {
    transform: scale(0.5);
  }

  &__text {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%);
    font-size: 14px;
    font-weight: 300;
    line-height: 120%;
    text-align: center;
    white-space: nowrap;
  }
}
