@use '../assets/vendor/utils.scss' as *;

.footer {
  margin-top: 64px;
  margin-bottom: 28px;
  flex-shrink: 0;

  @media screen and (max-width: 766px) {
    margin: 0;
  }

  &__container {
    padding: 32px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    background: #f3f3f3;
    border-radius: 32px;

    @media screen and (max-width: 766px) {
      margin-top: 76px;
      padding: 32px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 32px 32px 0px 0px;
    }

    &-left {
      padding-left: 8px;
      padding-right: 8px;
      max-width: 542px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 32px;

      &-description {
        font-size: 18px;
        line-height: 140%;
      }

      &-title {
        font-weight: 700;
      }

      &-nav {
        display: flex;
        align-items: center;
        gap: 24px;
        font-size: 14px;
        font-weight: 500;

        @media screen and (max-width: 766px) {
          font-size: 12px;
        }
      }
    }

    &-right {
      padding-right: 100px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      font-size: 14px;
      font-weight: 500;
      color: #4f4f4f;

      @media screen and (max-width: 766px) {
        padding: 0;
      }

      &-support {
        padding: 8px 12px 8px 8px;
        max-width: 250px;
        width: 100%;
        height: 62px;

        background: #e9e9e9;
        border: 2px solid #e9e9e9;
        border-radius: 16px;
        text-align: left;

        @media screen and (max-width: 766px) {
          margin: 32px 0;
        }
      }
    }
  }

  .button-with-icon {
    &__icon {
      width: 46px;
      height: 46px;
    }
  }
}
