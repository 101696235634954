@use '../../assets/vendor/utils.scss' as *;

.option-selector {
  position: relative;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 766px) {
    padding: 8px 0;
  }

  &.error {
    border: 2px solid $red-failed;
    border-radius: 16px;
  }

  &__error {
    position: absolute;
    top: -20px;
    left: 8px;
    padding-left: 8px;
    font-size: 14px;
    line-height: 120%;
    color: $red-failed;

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    gap: 8px;

    &-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.12px;
      color: #9f9f9f;

      @media screen and (max-width: 766px) {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: normal;
      }
    }

    &-line {
      border-top: 1px solid #d4d4d4;
      flex: 1 0;
    }

    &-count {
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.12px;
      color: #57c058;

      @media screen and (max-width: 766px) {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: normal;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__button {
    padding: 10px 20px;
    max-width: max-content;
    height: 40px;
    line-height: 120%;
    background: #f9f9fb;
    border-radius: 16px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 766px) {
      padding: 12px;
      height: 54px;
    }

    &.selected {
      background: #ffe97c;
    }
  }
}
