@use '../../../assets/vendor/utils.scss' as *;

.error-content {
  z-index: 1;
  padding: 24px 16px;
  background: $white;
  border-radius: 24px;
  box-shadow: 0px 4px 8px 0px rgba(255, 115, 115, 0.12);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &-icon {
      width: 112px;
      height: 112px;
      background: url(~assets/images/errorIcon.svg) center no-repeat;
      flex-shrink: 0;
    }

    &-title {
      font-size: 18px;
      font-weight: 700;
    }

    &-error {
      font-weight: 500;
      text-align: center;
    }

    &-text {
      line-height: 140%;
      text-align: center;
    }
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .button-with-icon {
      height: 40px;
      font-size: 14px;
      line-height: 120%;
      border-radius: 8px;
      background: #F0F0F0;
      border: 1px solid #F0F0F0;

      &__icon {
        width: 22px;
        height: 22px;
      }
    }
  }
}
