.page-error {
  margin: 90px auto;
  max-width: 370px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;

  @media screen and (max-width: 766px) {
    max-width: 250px;
    margin-top: 70px;
  }

  &__title {
    margin: 0;
    font-size: 160px;
    font-weight: 800;
    line-height: 191px;
    letter-spacing: 11px;

    @media screen and (max-width: 766px) {
      font-size: 108px;
      line-height: 129px;
    }
  }

  &__subtitle {
    line-height: 19px;
    text-align: center;
  }

  &__button {
    margin-top: 40px;
    width: 100%;
    height: 48px;
    background-color: #ffd500;
    border-radius: 16px;
  }
}
