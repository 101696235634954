@font-face {
  font-family: SF;
  font-weight: 300;
  font-style: normal;
  src: 
    url(./SF-Pro-Text-Light.woff) format("woff"),
    url(./SF-Pro-Text-Light.ttf) format("truetype"),
    url(./SF-Pro-Text-Light.otf) format("opentype");
}

@font-face {
  font-family: SF;
  font-weight: 400;
  font-style: normal;
  src: 
    url(./SF-Pro-Text-Regular.woff) format("woff"),
    url(./SF-Pro-Text-Regular.ttf) format("truetype"),
    url(./SF-Pro-Text-Regular.otf) format("opentype");
}

@font-face {
  font-family: SF;
  font-weight: 500;
  font-style: normal;
  src: 
    url(./SF-Pro-Text-Medium.woff) format("woff"),
    url(./SF-Pro-Text-Medium.ttf) format("truetype"),
    url(./SF-Pro-Text-Medium.otf) format("opentype");
}

@font-face {
  font-family: SF;
  font-weight: 600;
  font-style: normal;
  src: 
    url(./SF-Pro-Text-Semibold.woff) format("woff"),
    url(./SF-Pro-Text-Semibold.ttf) format("truetype"),
    url(./SF-Pro-Text-Semibold.otf) format("opentype");
}

@font-face {
  font-family: SF;
  font-weight: 700;
  font-style: normal;
  src: 
    url(./SF-Pro-Text-Bold.woff) format("woff"),
    url(./SF-Pro-Text-Bold.ttf) format("truetype"),
    url(./SF-Pro-Text-Bold.otf) format("opentype");
}

@font-face {
  font-family: SF;
  font-weight: 800;
  font-style: normal;
  src: 
    url(./SF-Pro-Text-Heavy.woff) format("woff"),
    url(./SF-Pro-Text-Heavy.ttf) format("truetype"),
    url(./SF-Pro-Text-Heavy.otf) format("opentype");
}