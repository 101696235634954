.popup {
  position: fixed;
  inset: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  opacity: 0;
  visibility: hidden;
  
  @media screen and (max-width: 766px) {
    left: 0;
    bottom: 0;
    align-items: flex-end;
  }

  &_opened {
    display: flex;
    opacity: 1;
    visibility: visible;
    overflow: scroll;
  }

  &__plate {
    padding: 32px 40px;
    max-width: 574px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 21px 42px -17px rgba(0, 47, 67, 0.24);

    @media screen and (max-width: 766px) {
      padding: 0 8px 32px;
      gap: 40px;
      border-radius: 16px 16px 0px 0px;
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.10);
    }
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 24px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      gap: 16px;
    }

    .lottie-animation {
      width: 139px;
      height: 137px;
      flex-shrink: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media screen and (max-width: 766px) {
      gap: 16px;
      text-align: center;
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
    }

    &-description {
      line-height: 140%;
    }
  }

  &__buttons {
    padding-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid #d7e2e4;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__button {
    padding: 16px 0;
    max-width: 244px;
    width: 100%;
    height: 54px;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      max-width: 328px;
    }

    &.stay {
      background-color: #f2f1ed;
      border: 1px solid #f2f1ed;
      transition: background-color 0.5s;
      cursor: pointer;

      @media screen and (max-width: 766px) {
        order: 2;
        padding: 10px 0;
        height: 44px;
      }

      &:hover {
        background-color: #fff;
      }
    }

    &.go {
      color: #939393;
      pointer-events: none;

      @media screen and (max-width: 766px) {
        order: 1;
        padding: 0;
        height: 20px;
      }

      & span {
        font-weight: 700;
      }
    }
  }
}
