@use './assets/fonts/fonts.css' as *;
@use './assets/vendor/utils.scss' as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // outline: 1px solid red;
}

html, body {
  height: 100%;
}

body {
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  font-family: SF;
  font-weight: 400;
  color: $black;

  overflow-x: hidden;
}

#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

button,
input {
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  -webkit-text-size-adjust: 100%;
}

button,
label {
  cursor: pointer;
}

a {
  font-family: inherit;
  color: inherit;
  text-decoration: none;
}

li {
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: #4f4f4f;
    border-radius: 50%;
    flex-shrink: 0;
  }
}

[class*="__inner"] {
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
}