.faqed {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__text {
    font-size: 14px !important;
    font-weight: 400 !important;
    pointer-events: none;
  } 

  &__icon {
    margin-left: 8px;
    width: 24px;
    height: 24px;
    background: url(~assets/images/faq.svg) center/contain no-repeat;
    flex-shrink: 0;
  }
}