@use '../../assets/vendor/utils.scss' as *;

.market-selector {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background: $gray;
  border-radius: 24px;

  @media screen and (max-width: 766px) {
    order: 2;
    padding: 24px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    background: $white;
    box-shadow: 0px 4px 6.5px 0px rgba(0, 0, 0, 0.08);
  }

  &__title {
    font-weight: 600;
    line-height: 120%;

    @media screen and (max-width: 766px) {
      padding-left: 8px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (min-width: 401px) and (max-width: 766px) {
      width: 100%;
      gap: 8px;
    }

    @media screen and (max-width: 400px) {
      flex-wrap: wrap;
    }
  }

  &__button-avito {
    gap: 25px !important;

    @media screen and (max-width: 766px) {
      gap: 8px !important;
    }
  }

  .button-with-icon {
    max-width: 247px;
    min-width: 247px;
    height: 56px;
    justify-content: start;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    background: $gray;
    border: none;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 766px) {
      padding-right: 16px;
      max-width: none;
      min-width: 150px;
      height: 78px;
      justify-content: center;
      gap: 8px;
      font-size: 18px;
    }

    &__icon {
      width: 56px;
      height: 56px;

      @media screen and (max-width: 766px) {
        background-size: cover !important;
      }
    }

    &.active {
      background: #ffe97c;

      @media screen and (max-width: 766px) {
        background: #ffe97c;
      }
    }
  }
}
