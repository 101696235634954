@use '../../../../assets/vendor/utils.scss' as *;

.services-card {
  max-width: 322px;
  width: 100%;
  background: $white;
  border-radius: 24px;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.16);

  &__top {
    padding: 18px 0 18px 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 24px 24px 0px 0px;

    &.yandex {
      background: linear-gradient(80deg, #ffd4a1 3%, #fff6be 92%);
    }

    &.avito {
      background: linear-gradient(80deg, #FF999A 3%, #FFFEDA 92%);
    }

    &-logo {
      width: 54px;
      height: 54px;

      border-radius: 12px;
      backdrop-filter: blur(4px);
      box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.12);

      &.yandex {
        background: url(~assets/images/yandexmapWithBg.svg) center/contain
          no-repeat;
      }

      &.avito {
        background: url(~assets/images/avito.svg) center/contain no-repeat;
      }
    }

    &-icon {
      width: 54px;
      height: 54px;
      border-radius: 12px;
      backdrop-filter: blur(42px);
      mix-blend-mode: color-burn;
      box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.12);

      &.icon-eye {
        background: url(~assets/images/eyeOrange.svg) center no-repeat;
      }

      &.icon-message {
        background: url(~assets/images/messageOrange.svg) center no-repeat;
      }
    }
  }

  &__container {
    padding: 16px 10px 12px;
    display: flex;
    flex-direction: column;
    gap: 46px;

    @media screen and (max-width: 766px) {
      gap: 38px;
    }
  }

  &__info {
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0px 2px 12px -17px rgba(73, 62, 97, 0.15);

    &-parameters {
      & p {
        font-size: 32px;
        font-weight: 700;
      }

      .faqed__children {
        font-size: 20px;
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-container {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      &-icon {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        background-position: center;
        background-repeat: no-repeat;

        &.search {
          background-image: url(~assets/images/search.svg);
        }

        &.action {
          background-image: url(~assets/images/action.svg);
        }
      }

      &-text {
        font-size: 12px;
        line-height: normal;
      }

      &-span {
        font-weight: 600;
      }
    }
  }

  &__bottom {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d7e2e4;

    & p {
      padding: 4px 6px;
      font-size: 20px;

      @media screen and (max-width: 766px) {
        font-size: 24px;
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__order-button {
    padding: 0 16px;
    width: 100%;
    height: 32px;
    font-weight: 500;
    background-color: #ffe14b;
    border: 1px solid #ffe14b;
    border-radius: 12px;
    transition: background-color 0.5s;

    &:hover {
      background-color: $white;
    }

    @media screen and (max-width: 766px) {
      height: 44px;
    }
  }

  &__settings-button {
    width: 32px;
    height: 32px;
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      width: 44px;
      height: 44px;
      border-radius: 18px;
    }

    .button-with-icon {
      &__icon {
        width: 22px;
        height: 22px;

        @media screen and (max-width: 766px) {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}
