@use '../assets/vendor/utils.scss' as *;

.header {
  margin: 0 auto;
  padding: 22px 18px 22px 40px;
  max-width: 1170px;
  width: 100%;
  background: #ffd500;
  border-radius: 0px 0px 32px 32px;

  @media screen and (max-width: 766px) {
    padding: 0;
    border-radius: 0px 0px 16px 16px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 766px) {
      padding: 26px 16px;
      gap: 16px;

      &-mobile {
        margin-top: 16px;
        padding: 8px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        & p {
          padding-left: 8px;
          padding-right: 8px;
          font-weight: 600;
          line-height: 120%;
        }
      }
    }
  }

  &__title {
    margin: 0;
    font-size: 32px;
    font-weight: 800;
    line-height: 105%;

    @media screen and (max-width: 766px) {
      max-width: 200px;
      font-size: 24px;
    }

    & span {
      font-weight: 700;
    }
  }

  &__logo {
    margin: 6px 40px;
    max-width: 136px;
    width: 100%;
    min-height: 42px;
    height: 100%;
    background: url(~assets/images/logo.svg) center/contain no-repeat;
    flex-shrink: 0;

    @media screen and (max-width: 766px) {
      margin: 4px 20px;
      width: 86px;
      height: 27px;
      background: url(~assets/images/logo.svg) center/contain no-repeat;
    }
  }
}
