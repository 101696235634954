@use '../../assets/vendor/utils.scss' as *;

.stars-rating {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 766px) {
    padding: 0;
  }

  &__item {
    width: 52px;
    height: 52px;
    background: url(~assets/images/star.svg) center/contain no-repeat;
    flex-shrink: 0;
    transition: all 0.3s;
    cursor: pointer;

    @media screen and (max-width: 766px) {
      width: 62px;
      height: 62px;
      background-size: cover;
    }

    &:hover {
      opacity: 0.6;
    }

    &.active {
      background: url(~assets/images/star-active.svg) center/contain no-repeat;
      flex-shrink: 0;
    }

    &-text {
      max-width: 270px;
      width: 100%;
      font-size: 12px;
      font-weight: 300;
      line-height: 120%;
      color: $gray-deep-dark;
    }
  }
}
